<template>
  <div class="CommonProblem document-content">常见问题</div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.CommonProblem {
}
</style>